<template>
  <v-container
    class="pa-0"
    style="height: 100%; max-width: 1250px;"
    fluid
  >
    <div
      class="pa-0 pa-2 pt-3"
      style="width: 100%;"
    >
      <v-row
        class="pa-0 ma-0 "
      >
        <tour
          ref="tour"
          name="dooh-analytics-tour"
          :steps="tourSteps"
        />
        <v-col
          class="pa-1 ma-0"
          cols="11"
          :sm="$vuetify.breakpoint.width < 820 ? '11 pr-0' : '4'"
          md="5"
        >
          <v-row
            id="dooh-analytics-select-campaign"
            class="pr-3"
          >
            <v-col
              md="11 pr-3"
              :sm="$vuetify.breakpoint.width < 820 ? '11 pr-0' : '11 pr-1'"
              :cols="$vuetify.breakpoint.width < 570 ? '11 pr-5' : '11 pr-0'"
              :class="$vuetify.breakpoint.width < 556
                && $vuetify.breakpoint.width > 414 ? 'pr-0' : ''"
            >
              <v-select
                v-model="campaignId"
                dense
                outlined
                item-text="name"
                item-value="id"
                hide-details
                clearable
                :items="campaigns"
                :label="$t('deconve.scopes.campaigns')"
                @change="onCampaignChanged"
              />
            </v-col>
            <v-col
              md="1"
              sm="1"
              :cols="$vuetify.breakpoint.width < 570 ? '1 pr-5' : '1'"
              class="pa-0 pb-3 pr-3 d-flex align-end justify-center"
            >
              <v-menu
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    icon
                    :color="'neutral'"
                    :class="'white'"
                    v-bind="attrs"
                    class="mx-2"
                    v-on="on"
                  >
                    <v-icon :small="$vuetify.breakpoint.mobile">
                      {{ 'mdi-filter-outline' }}
                    </v-icon>
                  </v-btn>
                </template>

                <v-card
                  style="max-width: 400px;"
                >
                  <v-list>
                    <v-list-item>
                      <date-filter
                        :start-date="campaignFilterStartDate"
                        :end-date="campaignFilterEndDate"
                        @change-start-date="onChangeCampaignFilterStartDate"
                        @change-end-date="onChangeCampaignFilterEndDate"
                      />
                    </v-list-item>
                  </v-list>
                  <v-list-item v-if="tags.length === 0">
                    <rectangle-button
                      icon="mdi-tag-plus-outline"
                      outlined
                      color="neutral"
                      @clicked="openTagModal"
                    >
                      {{ $t('deconve.tags') }}
                    </rectangle-button>
                    <v-switch
                      v-model="noTags"
                      inset
                      class="ml-4"
                      :label="$t('deconve.noTags')"
                    />
                  </v-list-item>

                  <v-list-item
                    v-else
                    class="align-end"
                  >
                    <tag
                      v-for="tag in tags"
                      :key="tag.id"
                      class="pa-1"
                      :tag-id="tag.id"
                    />
                    <div
                      class="d-flex flex-column ml-2"
                      style="flex: 1"
                    >
                      <square-button
                        menu-disabled
                        icon-name="mdi-tag-plus-outline"
                        outlined
                        color="transparentBackground"
                        style="margin-top: auto"
                        icon-color="neutral"
                        @clicked="openTagModal"
                      />
                    </div>
                  </v-list-item>
                  <v-card-actions class="justify-end">
                    <rectangle-button
                      v-if="hasCampaignFilterChanged"
                      color="primary"
                      @clicked="resetFilter"
                    >
                      {{ $t('deconve.clearFilters') }}
                    </rectangle-button>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.width < 820"
          class="pa-1 px-0 pb-2 d-flex align-end justify-center"
          sm="1"
          md="3"
          cols="1"
        >
          <div
            id="dooh-analytics-more-options"
          >
            <square-button
              outlined
              color="border"
              icon-color="neutral"
              icon-name="mdi-dots-vertical"
            >
              <v-list>
                <v-list-item
                  @click="openUnitManager"
                >
                  {{ $t('deconve.createPanel') }}
                </v-list-item>
                <v-list-item
                  @click="openCampaignManager"
                >
                  {{ $t('deconve.createCampaign') }}
                </v-list-item>
                <v-list-item
                  @click="openCampaignListManager"
                >
                  {{ $t('deconve.seeCampaigns') }}
                </v-list-item>
                <v-list-item
                  @click="openPanelListManager"
                >
                  {{ $t('deconve.seePanels') }}
                </v-list-item>
                <v-list-item
                  @click="exportReports"
                >
                  {{ $t('deconve.exportReport') }}
                </v-list-item>
                <v-list-item
                  @click="startTour"
                >
                  {{ $t('deconve.help') }}
                </v-list-item>
              </v-list>
            </square-button>
          </div>
        </v-col>
        <v-col
          id="dooh-analytics-select-panel"
          :class="$vuetify.breakpoint.width < 558
            && $vuetify.breakpoint.width > 414 ? 'pa-1 pl-1 ma-0'
            : $vuetify.breakpoint.width < 820 ? 'pa-1 pt-0 pl-1 ma-0' : 'pa-1 ma-0'"
          :cols="$vuetify.breakpoint.width < 415 ? '12' : '6 pl-0'"
          :sm="$vuetify.breakpoint.width < 820 ? '7' : '3'"
          md="3"
        >
          <v-select
            v-model="panelIds"
            dense
            outlined
            item-text="name"
            item-value="id"
            multiple
            hide-details
            clearable
            :loading="false"
            :items="panels"
            :label="$t('deconve.panels')"
            @change="onPanelChanged"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                {{ otherPanelSelectedText }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          id="dooh-analytics-report-date-filter"
          :sm="$vuetify.breakpoint.width < 820 ? '5' : '4'"
          md="3"
          :cols="$vuetify.breakpoint.width < 415 ? '12' : '6'"
          class="pa-1 d-flex align-end"
        >
          <date-filter
            width="100%"
            :start-date="startReportDate"
            :end-date="endReportDate"
            @change-start-date="changeReportStartDate"
            @change-end-date="changeReportEndDate"
          />
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.width > 820"
          class="pa-0 d-flex justify-end"
          sm="1"
          md="1"
          cols="2"
        >
          <div
            class="pa-1 d-flex align-end"
            style="gap: 5px;"
          >
            <v-btn
              icon
              :small="$vuetify.breakpoint.mobile"
              @click="startTour"
            >
              <v-icon
                color="neutral"
              >
                $tipsAndUpdatesIcon
              </v-icon>
            </v-btn>
            <div
              id="dooh-analytics-more-options"
            >
              <square-button
                outlined
                data-cy="apikey-button-more"
                color="border"
                icon-color="neutral"
                icon-name="mdi-dots-vertical"
              >
                <v-list>
                  <v-list-item
                    @click="openUnitManager"
                  >
                    {{ $t('deconve.createPanel') }}
                  </v-list-item>
                  <v-list-item
                    @click="openCampaignManager"
                  >
                    {{ $t('deconve.createCampaign') }}
                  </v-list-item>
                  <v-list-item
                    @click="openCampaignListManager"
                  >
                    {{ $t('deconve.seeCampaigns') }}
                  </v-list-item>
                  <v-list-item
                    @click="openPanelListManager"
                  >
                    {{ $t('deconve.seePanels') }}
                  </v-list-item>
                  <v-list-item
                    @click="exportReports"
                  >
                    {{ $t('deconve.exportReport') }}
                  </v-list-item>
                </v-list>
              </square-button>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
      >
        <v-col
          id="dooh-analytics-campaign-impact"
          cols="12"
          md="4"
          sm="12"
          class="pa-1"
        >
          <v-card
            outlined
            height="301"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title class="justify-center">
              {{ $t('deconve.impactInThePeriod') }}
            </v-card-title>
            <v-card-subtitle class="pt-0 pb-2 text-center">
              {{ campaignIntervalDate }}
            </v-card-subtitle>
            <v-card-text>
              <div class="text-center grey--text text--darken-1 font-weight-regular mx-0 pa-0">
                {{ $t('deconve.impactedVehicles') }}
              </div>
              <div class="text-center grey--text text--darken-1 font-weight-regular mx-0 pa-0">
                {{
                  $t(
                    'deconve.impactedVehicleRate',
                    [numberOfVehiclesImpacted, totalNumberOfVehicles]
                  )
                }}
              </div>
              <div
                class="text-center mt-3 subtitle-1 font-weight-medium textBlack--text"
              >
                {{ $t('deconve.impactedPeople') }}
              </div>
              <div
                class="text-center text-h4 font-weight-bold font-size-14 textBlack--text"
              >
                {{ numberOfPeopleImpacted }}
              </div>
              <div v-if="campaignId">
                <div
                  class="text-center subtitle-1 font-weight-medium textBlack--text mt-3"
                >
                  {{ $t('deconve.costPerMileImpressions') }}
                </div>
                <div
                  class="text-center text-h4 font-weight-bold font-size-14 textBlack--text"
                >
                  {{ costPerMileImpressions }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          id="dooh-analytics-people-impacted"
          class="pa-1"
          cols="12"
          md="8"
          sm="12"
        >
          <v-card
            class="d-flex flex-column pa-3 pt-0"
            style="display: flex; flex: 1; width: 100%;"
            elevation="0"
            outlined
            height="301"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title>{{ $t('deconve.impactedPeople') }}</v-card-title>
            <apexchart
              type="area"
              width="100%"
              height="207"
              :options="chartOptions"
              :series="series"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
      >
        <campaign-creator ref="campaignDialog" />
        <campaign-list ref="campaignListDialog" />
        <tags-manager-dialog ref="tagDialog" />
        <v-col
          id="dooh-analytics-audience-by-panel"
          class="pa-1"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card
            class="d-flex flex-column pa-3 pt-0"
            style="display: flex; flex: 1; width: 100%;"
            elevation="0"
            outlined
            height="301"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title>{{ $t('deconve.doohDashboard.audienceByPanel') }}</v-card-title>
            <apexchart
              type="bar"
              width="100%"
              height="230"
              :options="chartOptionsAudienceByPanel"
              :series="seriesAudienceByPanel"
            />
          </v-card>
        </v-col>
        <v-col
          id="dooh-analytics-report-by-vehicle-type"
          class="pa-1"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card
            class="d-flex flex-column pa-3 pt-0"
            style="display: flex; flex: 1; width: 100%;"
            elevation="0"
            outlined
            height="301"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title>{{ $t('deconve.vehicleTypes') }}</v-card-title>
            <apexchart
              type="donut"
              height="230"
              :options="chartOptionsPercentageVehicles"
              :series="seriesPercentageVehicles"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>

// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import DateFilter from '@/components/DateFilter.vue';
import CampaignCreator from '@/components/CampaignCreator.vue';
import CampaignList from '@/components/CampaignList.vue';
import SquareButton from '@/components/SquareButton.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import Tag from '@/components/Tag.vue';
import Tour from '@/components/Tour.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';

export default {
  name: 'DoohAudience',
  components: {
    DateFilter,
    CampaignCreator,
    SquareButton,
    RectangleButton,
    CampaignList,
    TagsManagerDialog,
    Tag,
    Tour,
  },
  data() {
    return {
      campaignFilterParams: {},
      noTags: false,
      tags: [],
      isLoadingCampaigns: false,
      campaignId: null,
      campaign: {},
      campaigns: [],
      seriesPercentageVehicles: [],
      chartOptionsPercentageVehicles: {
        dataLabels: {
          enabled: false,
        },
        labels: [],
        legend: {
          position: 'right',
        },
      },
      seriesAudienceByPanel: [{
        data: [],
      }],
      chartOptionsAudienceByPanel: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      panels: [],
      panelIds: [],
      startReportDate: '',
      endReportDate: '',
      campaignFilterStartDate: '',
      campaignFilterEndDate: '',
      defaultCampaignFilterStartDate: '',
      defaultCampaignFilterEndDate: '',
      series: [],
      chartOptions: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: [this.$vuetify.theme.themes.light.borderChart],
          curve: 'smooth',
        },
        xaxis: {
          type: 'datatime',
        },
        fill: {
          colors: [this.$vuetify.theme.themes.light.colorChart],
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },
      chartDataByDay: {},
      chartDataByHour: {},
      updateReportsTimer: '',
      tourSteps: [
        {
          target: '#dooh-analytics-more-options',
          content: this.$t('deconve.onboarding.doohAnalytics.mainMenu.description'),
        },
        {
          target: '#dooh-analytics-select-campaign',
          content: this.$t('deconve.onboarding.doohAnalytics.selectCampaign.description'),
        },
        {
          target: '#dooh-analytics-select-panel',
          content: this.$t('deconve.onboarding.doohAnalytics.selectPanel.description'),
        },
        {
          target: '#dooh-analytics-report-date-filter',
          content: this.$t('deconve.onboarding.doohAnalytics.reportDateFilter.description'),
        },
        {
          target: '#dooh-analytics-campaign-impact',
          content: this.$t('deconve.onboarding.doohAnalytics.campaignImpact.description'),
        },
        {
          target: '#dooh-analytics-people-impacted',
          content: this.$t('deconve.onboarding.doohAnalytics.peopleImpacted.description'),
        },
        {
          target: '#dooh-analytics-audience-by-panel',
          content: this.$t('deconve.onboarding.doohAnalytics.audienceByPanel.description'),
        },
        {
          target: '#dooh-analytics-report-by-vehicle-type',
          content: this.$t('deconve.onboarding.doohAnalytics.reportByVehicleType.description'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      units: 'units/units',
      getUnit: 'units/getUnit',
      videos: 'vehiclecounter/videos',
      reportsByDay: 'dooh/reportsByDay',
      getNumberOfPeopleImpacted: 'dooh/numberOfPeopleImpacted',
      getNumberOfVehiclesImpacted: 'dooh/numberOfVehiclesImpacted',
      getTotalNumberOfVehicles: 'dooh/totalNumberOfVehicles',
      getNumberOfPeopleImpactedByCampaign: 'dooh/numberOfPeopleImpactedByCampaign',
      getNumberOfVehiclesImpactedByCampaign: 'dooh/numberOfVehiclesImpactedByCampaign',
      getTotalNumberOfVehiclesByCampaign: 'dooh/totalNumberOfVehiclesByCampaign',
      campaignCostPerMileImpressions: 'dooh/campaignCostPerMileImpressions',
      isLoadingReportsByDay: 'dooh/isLoadingReportsByDay',
      reportsByDayToChartData: 'dooh/reportsByDayToChartData',
      reportsPercentage: 'dooh/reportsPercentage',
      reportsToChartAudience: 'dooh/reportsToChartAudience',
      getCampaign: 'dooh/getCampaign',
    }),
    otherPanelSelectedText() {
      const count = this.panelIds.length - 1;

      return this.$tc('deconve.otherItems', count, { count });
    },
    panelNames() {
      return this.panels.map((panel) => panel.name);
    },
    hasCampaignFilterChanged() {
      return this.campaignFilterStartDate !== this.defaultCampaignFilterStartDate
        || this.campaignFilterEndDate !== this.defaultCampaignFilterEndDate
        || this.tags.length > 0 || this.noTags;
    },
    campaignIntervalDate() {
      let startDate = null;
      let endDate = null;

      if (this.campaignId) {
        const { start_at: startAt, end_at: endAt } = this.campaign;

        startDate = startAt;
        endDate = endAt;
      } else if (this.startReportDate && this.endReportDate) {
        startDate = this.startReportDate;
        endDate = this.endReportDate;
      } else {
        return '';
      }

      const startDateStr = this.$moment(startDate).format('DD/MM/YYYY');
      const endDateStr = this.$moment(endDate).format('DD/MM/YYYY');

      return this.$t('deconve.campaignIntervalDate', [startDateStr, endDateStr]);
    },
    costPerMileImpressions() {
      // In standard notation, 0.001 is showed as 0.00. But in compact notation it is showed as
      // 0.001. So, we use compact notation for low values
      if (this.campaignCostPerMileImpressions >= 0) {
        const options = {
          style: 'currency',
          currency: 'BRL',
          notation: this.campaignCostPerMileImpressions >= 0.01 ? 'standard' : 'compact',
        };

        return new Intl.NumberFormat('pt-BR', options).format(this.campaignCostPerMileImpressions);
      }

      return '';
    },
    numberOfPeopleImpacted() {
      if (this.campaignId) return this.getNumberOfPeopleImpactedByCampaign;

      return this.getNumberOfPeopleImpacted;
    },
    numberOfVehiclesImpacted() {
      if (this.campaignId) return this.getNumberOfVehiclesImpactedByCampaign;

      return this.getNumberOfVehiclesImpacted;
    },
    totalNumberOfVehicles() {
      if (this.campaignId) return this.getTotalNumberOfVehiclesByCampaign;

      return this.getTotalNumberOfVehicles;
    },
  },
  watch: {
    reportsByDayToChartData() {
      const { labels, data } = this.reportsByDayToChartData;
      const { xaxis } = this.chartOptions;

      this.chartOptions = { ...this.chartOptions, xaxis: { ...xaxis, categories: labels } };
      this.series = [{ data, name: this.$t('deconve.people') }];
    },
    reportsToChartAudience() {
      const { labels, data } = this.reportsToChartAudience;
      const { xaxis } = this.chartOptionsAudienceByPanel;

      // to update audience by panels at apexcharts
      this.chartOptionsAudienceByPanel = {
        ...this.chartOptionsAudienceByPanel,
        xaxis: { ...xaxis, categories: labels },
      };

      this.seriesAudienceByPanel = [{ data, name: this.$t('deconve.people') }];
    },
    reportsPercentage() {
      const {
        bus, car, motorbike, truck,
      } = this.reportsPercentage;

      this.seriesPercentageVehicles = [car.total, bus.total, motorbike.total, truck.total];

      // to update labels at apexcharts
      this.chartOptionsPercentageVehicles = {
        ...this.chartOptionsPercentageVehicles,
        labels: [
          this.$t('deconve.vehicleType.car', [car.percentage]),
          this.$t('deconve.vehicleType.bus', [bus.percentage]),
          this.$t('deconve.vehicleType.motorbike', [motorbike.percentage]),
          this.$t('deconve.vehicleType.truck', [truck.percentage]),
        ],
      };
    },
    noTags() {
      this.updateCampaignsByIntervalDate();
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);
    this.initDateRange();
    this.refreshData();
    this.setTimerToUpdateReports();
    this.getParamsToFilterCampaign();
  },
  beforeDestroy() {
    clearInterval(this.updateReportsTimer);
  },
  methods: {
    ...mapActions({
      getReportsByDay: 'dooh/getReportsByDay',
      downloadReportByHour: 'dooh/downloadReportByHour',
      fetchVehicleCounterVideos: 'vehiclecounter/fetchVideos',
      fetchVideos: 'videos/fetchVideos',
      fetchUnits: 'units/fetchUnits',
      fetchCampaigns: 'dooh/fetchCampaigns',
      resetCampaignData: 'dooh/resetCampaignData',
    }),
    startTour() {
      this.$refs.tour.start();
    },
    initDateRange() {
      const oneMonthBefore = this.$moment().subtract(1, 'month');

      this.campaignFilterStartDate = oneMonthBefore.startOf('day').toISOString();
      this.campaignFilterEndDate = this.$moment().endOf('day').toISOString();
      this.defaultCampaignFilterStartDate = this.campaignFilterStartDate;
      this.defaultCampaignFilterEndDate = this.campaignFilterEndDate;

      this.startReportDate = this.campaignFilterStartDate;
      this.endReportDate = this.campaignFilterEndDate;
    },
    updateReportsByDay() {
      const params = {
        startDate: this.startReportDate,
        endDate: this.endReportDate,
      };

      if (this.panelIds.length > 0) {
        params.panelIds = this.panelIds;
      }

      if (this.campaignId) {
        params.campaign = { ...this.campaign };
      }

      this.getReportsByDay(params);
    },
    setPanels() {
      if (this.campaignId) {
        const panels = [];

        this.campaign.units.forEach(({ id }) => {
          const unit = this.getUnit(id);

          if (unit) {
            panels.push(unit);
          } else {
            panels.push({ id, name: id });
          }
        });

        this.panels = panels;
      } else {
        this.panels = this.units;
      }
    },
    refreshData() {
      this.isLoadingCampaigns = true;

      this.fetchCampaigns(this.campaignFilterParams).then((campaigns) => {
        this.campaigns = campaigns;
        this.isLoadingCampaigns = false;
        const promises = [this.fetchVehicleCounterVideos(), this.fetchVideos(), this.fetchUnits()];

        Promise.all(promises).then(() => {
          this.setPanels();
          this.updateReportsByDay();
        });
      }).catch(() => {
        this.isLoadingCampaigns = false;
      });
    },
    onPanelChanged() {
      this.updateReportsByDay();
    },
    onCampaignChanged() {
      this.panelIds = [];

      if (this.campaignId) {
        this.campaign = this.campaigns.find(({ id }) => id === this.campaignId);
        const { start_at: startAt, end_at: endAt } = this.campaign;

        this.startReportDate = this.$moment(startAt).startOf('day').toISOString();
        this.endReportDate = this.$moment(endAt).endOf('day').toISOString();
      } else {
        this.resetCampaignData();
      }

      this.setPanels();
      this.updateReportsByDay();
    },
    getParamsToFilterCampaign() {
      // To filter the campaigns that are active in the selected range date
      this.campaignFilterParams = {
        startBefore: this.campaignFilterEndDate,
        endAfter: this.campaignFilterStartDate,
      };

      if (this.tags) {
        this.campaignFilterParams.tags = this.tags;
      }

      if (this.noTags) {
        this.campaignFilterParams.noTags = this.noTags;
      }
    },
    updateCampaignsByIntervalDate() {
      this.isLoadingCampaigns = true;
      this.getParamsToFilterCampaign();

      this.fetchCampaigns(this.campaignFilterParams).then((campaigns) => {
        this.campaigns = campaigns;
        this.isLoadingCampaigns = false;
      }).catch(() => {
        this.isLoadingCampaigns = false;
      });
    },
    resetFilter() {
      this.initDateRange();
      this.tags = [];
      this.noTags = false;
      this.updateCampaignsByIntervalDate();
      this.updateReportsByDay();
    },
    openTagModal() {
      this.$refs.tagDialog.open(this.tags).then((response) => {
        if (response) {
          this.tags = response;
          this.updateCampaignsByIntervalDate();
        }
      });
    },
    onChangeCampaignFilterStartDate(newDate) {
      this.campaignFilterStartDate = this.$moment(newDate).startOf('day').toISOString();
      this.updateCampaignsByIntervalDate();
    },
    onChangeCampaignFilterEndDate(newDate) {
      this.campaignFilterEndDate = this.$moment(newDate).endOf('day').toISOString();
      this.updateCampaignsByIntervalDate();
    },
    changeReportStartDate(newDate) {
      this.startReportDate = this.$moment(newDate).startOf('day').toISOString();
      this.updateReportsByDay();
    },
    changeReportEndDate(newDate) {
      this.endReportDate = this.$moment(newDate).startOf('day').toISOString();
      this.updateReportsByDay();
    },
    exportReports() {
      const params = {
        startDate: this.startReportDate,
        endDate: this.endReportDate,
      };

      if (this.panelId) {
        params.panelId = this.panelId;
      }

      this.downloadReportByHour(params);
    },
    openUnitManager() {
      this.$router.push({ name: 'unit-register' });
    },
    openCampaignManager() {
      this.$refs.campaignDialog.open().then(() => {
        this.updateCampaignsByIntervalDate();
      });
    },
    openCampaignListManager() {
      this.$refs.campaignListDialog.open().then(() => {
        this.updateCampaignsByIntervalDate();
      });
    },
    openPanelListManager() {
      this.$router.push({ name: 'units' });
    },
    setTimerToUpdateReports() {
      const fiveMinutes = 5 * 60 * 1000;

      this.updateReportsTimer = setInterval(this.refreshData, fiveMinutes);
    },
  },
};
</script>
