<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="550px"
      @keydown.esc="cancel"
    >
      <tour
        ref="tour"
        name="campaign-creator-tour"
        :steps="tourSteps"
      />
      <v-card>
        <div
          class="d-flex justify-space-between align-center pl-2 pr-6"
        >
          <v-card-title>
            {{ isEditMode ? $t('deconve.editCampaign') : $t('deconve.createCampaign') }}
          </v-card-title>
          <v-btn
            icon
            small
            @click="startTour"
          >
            <v-icon color="neutral">
              $tipsAndUpdatesIcon
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row
                class="pt-3"
              >
                <v-col
                  cols="12"
                  sm="9"
                  md="9"
                  xs="9"
                  :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : 'ma-0 pa-0 pr-2'"
                >
                  <v-text-field
                    id="dooh-analytics-campaign-name"
                    v-model="nameCampaign"
                    max-width="100%"
                    counter="64"
                    :rules="nameRules"
                    :label="$t('deconve.name')"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  xs="3"
                  :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : 'ma-0 pa-0'"
                >
                  <v-text-field
                    id="dooh-analytics-campaign-value"
                    v-model="valueCampaign"
                    type="number"
                    max-width="100%"
                    :rules="valueRules"
                    :label="$t('deconve.doohDashboard.value')"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col
                  class="ma-0 pa-0"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <span
                    id="dooh-analytics-campaign-periods"
                    class="font-weight-medium caption text-sm-body-1"
                  >
                    {{ $t('deconve.doohDashboard.periods') }}
                  </span>
                </v-col>
                <v-col
                  class="ma-0 pa-0 pb-3 pr-2"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <date-filter
                    :start-date="startDate"
                    :end-date="endDate"
                    @change-start-date="changeStartDate"
                    @change-end-date="changeEndDate"
                  />
                </v-col>
                <v-col
                  class="ma-0 pa-0 pb-3"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div class="d-flex justify-space-between align-center">
                    <span
                      id="dooh-analytics-campaign-tags"
                      class="font-weight-medium caption text-sm-body-1"
                    >
                      {{ $t('deconve.tags') }}
                    </span>
                    <square-button
                      v-if="tags.length > 0"
                      menu-disabled
                      icon-name="mdi-tag-plus-outline"
                      outlined
                      color="transparentBackground"
                      style="margin-top: auto"
                      icon-color="neutral"
                      @clicked="openTagModal"
                    />
                  </div>
                  <rectangle-button
                    v-if="tags.length === 0"
                    icon="mdi-tag-plus-outline"
                    outlined
                    color="neutral"
                    @clicked="openTagModal"
                  >
                    {{ $t('deconve.tag.addTags') }}
                  </rectangle-button>
                  <tags-manager-dialog ref="tagsDialog" />
                  <unit-manager-dialog ref="unitManagerDialog" />
                  <div class="d-flex flex-wrap">
                    <tag
                      v-for="(tag, index) in tags"
                      :key="tag.id"
                      show-delete-label
                      class="pa-1"
                      :tag-id="tag.id"
                      @delete="deleteTag(index)"
                    />
                    <div
                      v-if="tags.length > 0"
                      class="d-flex flex-column ml-2"
                      style="flex: 1"
                    />
                  </div>
                </v-col>
                <v-col
                  class="ma-0 pa-0 pb-3"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div class="d-flex justify-space-between align-center">
                    <span
                      id="dooh-analytics-campaign-panels"
                      class="font-weight-medium caption text-sm-body-1"
                    >
                      {{ $t('deconve.panels') }}
                    </span>
                    <square-button
                      v-if="panels.length > 0"
                      menu-disabled
                      icon-name="mdi-tag-plus-outline"
                      outlined
                      color="transparentBackground"
                      style="margin-top: auto"
                      icon-color="neutral"
                      @clicked="openPanelModal"
                    />
                  </div>
                  <rectangle-button
                    v-if="panels.length === 0"
                    outlined
                    color="neutral"
                    data-cy="apikeymanager-add-tags"
                    @clicked="openPanelModal"
                  >
                    {{ $t('deconve.addPanels') }}
                  </rectangle-button>
                  <tags-manager-dialog ref="tagsDialog" />
                  <div class="d-flex flex-wrap">
                    <tag
                      v-for="(panel, index) in panels"
                      :key="panel.id"
                      show-delete-label
                      :label="panel.name"
                      :value="panel.exposureRate+'%'"
                      class="pa-1"
                      @delete="deleteTagPanel(index)"
                    />
                    <div
                      v-if="tags.length > 0"
                      class="d-flex flex-column ml-2"
                      style="flex: 1"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <rectangle-button
            color="negative"
            outlined
            @clicked="cancel"
          >
            {{ $t('deconve.cancel') }}
          </rectangle-button>
          <rectangle-button
            color="primary"
            :is-loading="isLoadingCampaign"
            :disabled="!valid || panels.length <= 0 || !startDate || !endDate"
            @clicked="onSubmitButtonClicked"
          >
            {{ isEditMode ? $t('deconve.submit') : $t('deconve.save') }}
          </rectangle-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { mdiHelp } from '@mdi/js';

import Tour from '@/components/Tour.vue';
import Tag from './Tag.vue';
import TagsManagerDialog from './TagsManagerDialog.vue';
import UnitManagerDialog from './UnitManagerDialog.vue';
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';
import DateFilter from './DateFilter.vue';

export default {
  name: 'CampaignCreator',
  components: {
    RectangleButton,
    SquareButton,
    DateFilter,
    TagsManagerDialog,
    UnitManagerDialog,
    Tag,
    Tour,
  },
  data() {
    return {
      icons: { mdiHelp },
      isEditMode: false,
      isLoadingCampaign: false,
      campaignData: '',
      campaignId: '',
      valid: false,
      valueCampaign: null,
      startDate: '',
      endDate: '',
      nameCampaign: null,
      dialog: false,
      tags: [],
      panels: [],
      resolve: null,
      tourSteps: [
        {
          target: '#dooh-analytics-campaign-name',
          content: this.$t('deconve.onboarding.doohAnalytics.campaignName.description'),
        },
        {
          target: '#dooh-analytics-campaign-value',
          content: this.$t('deconve.onboarding.doohAnalytics.campaignValue.description'),
        },
        {
          target: '#dooh-analytics-campaign-periods',
          content: this.$t('deconve.onboarding.doohAnalytics.campaignPeriods.description'),
          params: {
            placement: 'top',
          },
        },
        {
          target: '#dooh-analytics-campaign-tags',
          content: this.$t('deconve.onboarding.doohAnalytics.campaignTags.description'),
          params: {
            placement: 'top',
          },
        },
        {
          target: '#dooh-analytics-campaign-panels',
          content: this.$t('deconve.onboarding.doohAnalytics.campaignPanels.description'),
          params: {
            placement: 'top',
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: 'dooh/getCampaign',
      getTag: 'tags/getTag',
      getUnit: 'units/getUnit',
    }),
    nameRules() {
      return [
        (v) => !!v || this.$t('deconve.inputRules.name.required'),
        (v) => (v && v.length <= 64) || this.$t('deconve.inputRules.name.limit'),
      ];
    },
    valueRules() {
      return [
        (v) => !!v || this.$t('deconve.invalidValue'),
        (v) => (v && parseFloat(v) >= 0) || this.$t('deconve.invalidValue'),
      ];
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);
    this.initDateRange();
  },
  methods: {
    ...mapActions({
      addCampaign: 'dooh/addCampaign',
      editCampaign: 'dooh/editCampaign',
    }),
    initDateRange() {
      const oneMonthBefore = this.$moment().add(-1, 'months');

      this.changeStartDate(oneMonthBefore.toISOString());
      this.changeEndDate(this.$moment().toISOString());
    },
    startTour() {
      this.$refs.tour.start();
    },
    toDecimal(percent) {
      return (parseFloat(percent) / 100);
    },
    submit() {
      this.isLoadingCampaign = true;
      const tagIds = this.tags.map(({ id }) => id);
      const panels = this.panels.map((panel) => (
        {
          id: panel.id,
          // eslint-disable-next-line
          exposure_rate: this.toDecimal(panel.exposureRate),
        }));

      const params = {
        name: this.nameCampaign,
        // eslint-disable-next-line @typescript-eslint/camelcase
        value: this.valueCampaign,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_at: this.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_at: this.endDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        units: panels,
        // eslint-disable-next-line @typescript-eslint/camelcase
        tag_ids: tagIds,
      };

      this.addCampaign(params).then(() => {
        this.isLoadingCampaign = false;
        this.dialog = false;
        this.reset();
        this.resolve();
      }).catch(() => {
        this.isLoadingCampaign = false;
      });
    },
    submitCampaignUpdate() {
      this.isLoadingCampaign = true;
      const tagIds = this.tags.map(({ id }) => id);
      const panels = this.panels.map((panel) => (
        {
          id: panel.id,
          // eslint-disable-next-line
          exposure_rate: this.toDecimal(panel.exposureRate),
        }));

      const data = {
        tagIds,
        campaignId: this.campaignId,
        name: this.nameCampaign,
        value: this.valueCampaign,
        startAt: this.startDate,
        endAt: this.endDate,
        units: panels,
      };

      this.editCampaign(data).then(() => {
        this.isLoadingCampaign = false;
        this.dialog = false;
        this.reset();
        this.resolve();
      }).catch(() => {
        this.isLoadingCampaign = false;
      });
    },
    onSubmitButtonClicked() {
      if (this.isEditMode) {
        this.submitCampaignUpdate();
      } else {
        this.submit();
      }
    },
    open(campaignId) {
      this.dialog = true;
      this.initMode(campaignId);
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    initMode(campaignId) {
      if (campaignId) {
        this.campaignId = campaignId;
        this.isEditMode = true;
        this.campaignData = this.getCampaign(this.campaignId);
        this.initEdit();
      } else {
        this.isEditMode = false;
      }
    },
    initEdit() {
      const {
        name,
        value,
        start_at: startAt,
        end_at: endAt,
        tags,
        units,
      } = this.campaignData;

      this.nameCampaign = name || '';
      this.valueCampaign = value || '';
      this.startDate = startAt || '';
      this.endDate = endAt || '';
      this.tags = tags;
      this.panels = units.map((unit) => {
        const namePanel = this.getUnit(unit.id)?.name;

        // eslint-disable-next-line @typescript-eslint/camelcase
        return { id: unit.id, name: namePanel, exposureRate: (unit.exposure_rate) * 100 };
      });
    },
    openTagModal() {
      this.$refs.tagsDialog.open(this.tags, true).then((tags) => {
        if (tags) {
          this.tags = tags.map((tag) => ({ id: tag.id }));
        }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch(() => {});
    },
    openPanelModal() {
      this.$refs.unitManagerDialog.open(this.panels).then((panels) => {
        if (panels) {
          this.panels = panels;
        }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch(() => {});
    },
    reset() {
      this.$refs.form.reset();

      this.initDateRange();
      this.panels = [];
      this.tags = [];
    },
    cancel() {
      this.reset();
      this.dialog = false;
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
    },
    deleteTagPanel(index) {
      this.panels.splice(index, 1);
    },
    changeStartDate(newDate) {
      this.startDate = this.$moment(newDate).startOf('day').toISOString();
    },
    changeEndDate(newDate) {
      this.endDate = this.$moment(newDate).endOf('day').toISOString();
    },
  },
};
</script>

<style scoped>
  textarea:focus {
    outline: 0;
  }
</style>
