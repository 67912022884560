<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="550px"
      @keydown.esc="dialog = false"
    >
      <v-card>
        <v-card-title
          class="pb-6"
        >
          <span class="text-h5">{{ $t('deconve.campaigns') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <input-text
              v-model="search"
              left-icon="mdi-magnify"
              :placeholder="$t('deconve.search')"
              @focused="isListActive = true"
            />
            <div
              class="mb-2 mt-6"
              style="max-height: 300px; overflowY: auto"
            >
              <v-data-table
                style="flex: 1; border: 1px solid"
                :style="{borderColor: $vuetify.theme.themes.light.border}"
                :headers="tableHeaders"
                :search="search"
                :items-per-page="10"
                :items="campaigns"
                :loading="isLoadingCampaigns"
                data-cy="campaigns-data-table"
                class="elevation-0"
              >
                <template v-slot:[`item.name`]="{item}">
                  <span>{{ item.name }}</span>
                </template>
                <template v-slot:[`item.created_at`]="{item}">
                  <span>{{ item.created_at | moment('DD/MM/YYYY') }}</span>
                </template>
                <template v-slot:[`item.tags`]="{item}">
                  <div
                    class="d-flex"
                    style="width: 100%; justify-content: center"
                  >
                    <div
                      class="pa-2 d-flex flex-wrap"
                      style="width: fit-content"
                    >
                      <tag
                        v-for="tag in item.tags"
                        :key="tag.id"
                        class="pr-1 pb-1"
                        small
                        :tag-id="tag.id"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{item}">
                  <square-button
                    outlined
                    data-cy="member-button-more"
                    content-class="ml-2"
                    color="border"
                    icon-color="neutral"
                    icon-name="mdi-dots-vertical"
                  >
                    <v-list>
                      <v-list-item
                        @click="onDeleteCampaignClicked(item.id, item.name)"
                      >
                        {{ $t('deconve.delete') }}
                      </v-list-item>
                      <v-list-item
                        @click="openEditCampaign(item.id)"
                      >
                        {{ $t('deconve.edit') }}
                      </v-list-item>
                    </v-list>
                  </square-button>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <rectangle-button
            color="neutral"
            outlined
            @clicked="closeCampaignList"
          >
            {{ $t('deconve.close') }}
          </rectangle-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation-dialog ref="confirmationDialog" />
    <campaign-creator ref="campaignEditDialog" />
  </v-row>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import InputText from './InputText.vue';
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';
import CampaignCreator from './CampaignCreator.vue';
import ConfirmationDialog from './ConfirmationDialog.vue';
import Tag from './Tag.vue';

export default {
  name: 'CampaignList',
  components: {
    RectangleButton,
    Tag,
    InputText,
    SquareButton,
    CampaignCreator,
    ConfirmationDialog,
  },
  data: () => ({
    resolve: null,
    isCampaignBeingDeleted: false,
    search: '',
    dialog: false,
  }),
  computed: {
    ...mapGetters({
      campaigns: 'dooh/campaigns',
      isLoadingCampaigns: 'dooh/isLoadingCampaigns',
    }),
    tableHeaders() {
      return [
        { text: this.$t('deconve.name'), value: 'name' },
        { text: this.$t('deconve.createdAt'), value: 'created_at' },
        { text: this.$t('deconve.tags'), value: 'tags', align: 'center' },
        {
          text: this.$t('deconve.actions'), value: 'actions', align: 'end', sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      deleteCampaign: 'dooh/deleteCampaign',
      fetchAllCampaigns: 'dooh/fetchAllCampaigns',
    }),
    open() {
      this.dialog = true;

      this.fetchAllCampaigns();

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    openEditCampaign(campaignId) {
      this.$refs.campaignEditDialog.open(campaignId).then();
    },
    closeCampaignList() {
      this.dialog = false;
      this.resolve();
    },
    onDeleteCampaignClicked(id, name) {
      this.$refs.confirmationDialog.open(
        this.$t('deconve.deleteCampaignTitle'),
        this.$t('deconve.deleteCampaignMessage', { n: name }),
      ).then((response) => {
        if (response) {
          this.isCampaignBeingDeleted = true;
          this.deleteCampaign(id)
            .then(() => {
              this.isCampaignBeingDeleted = false;
              this.fetchAllCampaigns();
            })
            .catch((error) => {
              this.deleteError = error;
              this.isCampaignBeingDeleted = false;
              this.fetchAllCampaigns();
            });
        }
      });
    },
  },
};
</script>
