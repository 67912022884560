var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"550px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-6"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('deconve.campaigns')))])]),_c('v-card-text',[_c('v-container',{staticClass:"pa-0"},[_c('input-text',{attrs:{"left-icon":"mdi-magnify","placeholder":_vm.$t('deconve.search')},on:{"focused":function($event){_vm.isListActive = true}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"mb-2 mt-6",staticStyle:{"max-height":"300px","overflowY":"auto"}},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"flex":"1","border":"1px solid"},style:({borderColor: _vm.$vuetify.theme.themes.light.border}),attrs:{"headers":_vm.tableHeaders,"search":_vm.search,"items-per-page":10,"items":_vm.campaigns,"loading":_vm.isLoadingCampaigns,"data-cy":"campaigns-data-table"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'DD/MM/YYYY')))])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","justify-content":"center"}},[_c('div',{staticClass:"pa-2 d-flex flex-wrap",staticStyle:{"width":"fit-content"}},_vm._l((item.tags),function(tag){return _c('tag',{key:tag.id,staticClass:"pr-1 pb-1",attrs:{"small":"","tag-id":tag.id}})}),1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('square-button',{attrs:{"outlined":"","data-cy":"member-button-more","content-class":"ml-2","color":"border","icon-color":"neutral","icon-name":"mdi-dots-vertical"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteCampaignClicked(item.id, item.name)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.delete'))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.openEditCampaign(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.edit'))+" ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('rectangle-button',{attrs:{"color":"neutral","outlined":""},on:{"clicked":_vm.closeCampaignList}},[_vm._v(" "+_vm._s(_vm.$t('deconve.close'))+" ")])],1)],1)],1),_c('confirmation-dialog',{ref:"confirmationDialog"}),_c('campaign-creator',{ref:"campaignEditDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }