<template>
  <v-tour
    :name="name"
    :steps="tourSteps"
    :options="options"
  />
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

export default {
  name: 'Tour',
  props: {
    name: { type: String, required: true },
    // eslint-disable-next-line vue/require-default-prop
    steps: Array,
  },
  data() {
    return {
      options: {
        useKeyboardNavigation: false,
        // highlight blocks the 'parent' component scroll. When using it in mobile, the result is
        // not good
        highlight: false,
        labels: {
          buttonSkip: this.$t('deconve.done'),
          buttonPrevious: this.$t('deconve.previous'),
          buttonNext: this.$t('deconve.next'),
          buttonStop: this.$t('deconve.close'),
        },
      },
    };
  },
  computed: {
    tourSteps() {
      // Adds an offset to skip the app bar height
      const appBarOffset = -200;

      return this.steps.map((step) => ({ ...step, offset: appBarOffset }));
    },
  },
  methods: {
    start() {
      this.$tours[this.name].start();
    },
  },
};
</script>
