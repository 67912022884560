<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="cancel"
  >
    <tour
      ref="tour"
      name="panel-manager-tour"
      :steps="tourSteps"
    />

    <v-card>
      <div
        class="d-flex justify-space-between align-center pl-2 pr-6"
      >
        <v-card-title>
          {{ $t('deconve.panels') }}
        </v-card-title>
        <v-btn
          icon
          elevation="0"
          :small="$vuetify.breakpoint.mobile"
          @click="startTour"
        >
          <v-icon color="neutral">
            $tipsAndUpdatesIcon
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <input-text
            v-model="search"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
            @focused="isListActive = true"
          />
          <div
            id="panel-manager-panels"
            class="mb-2 mt-6"
            style="max-height: 300px; overflowY: auto"
          >
            <v-data-table
              v-model="selected"
              style="flex: 1; border: 1px solid"
              :style="{borderColor: $vuetify.theme.themes.light.border}"
              :headers="tableHeaders"
              :search="search"
              :items="items"
              data-cy="panel-manager-data-table"
              class="elevation-0"
              show-select
            >
              <template v-slot:[`item.exposureRate`]="{item}">
                <v-text-field
                  ref="input"
                  v-model="item.exposureRate"
                  suffix="%"
                  type="number"
                  step="0.1"
                  min="0"
                  max="100"
                  max-width="100%"
                  outlined
                  dense
                  hide-details
                  :rules="exposureRules"
                />
              </template>
            </v-data-table>
          </div>
        </v-form>
        <v-text-field
          id="panel-manager-exposure-rate-for-all-panels"
          v-model="exposureRateForAllPanels"
          class="pt-3"
          dense
          outlined
          suffix="%"
          type="number"
          step="0.1"
          min="0"
          max="100"
          max-width="100%"
          :rules="exposureAllRules"
          :label="$t('deconve.applyToAll')"
          @keyup.enter="applyAll"
        />
      </v-card-text>
      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <rectangle-button
          outlined
          color="neutral"
          @clicked="cancel"
        >
          {{ $t('deconve.cancel') }}
        </rectangle-button>
        <rectangle-button
          color="primary"
          data-cy="tag-confirm"
          :disabled="!valid || selected.length <= 0"
          @clicked="confirm"
        >
          {{ $t('deconve.confirm') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapGetters } from 'vuex';
import { mdiHelp } from '@mdi/js';

import RectangleButton from './RectangleButton.vue';
import InputText from './InputText.vue';
import Tour from './Tour.vue';

export default {
  name: 'UnitManagerDialog',
  components: {
    RectangleButton,
    InputText,
    Tour,
  },
  data() {
    return {
      icons: { mdiHelp },
      valid: false,
      exposureRateForAllPanels: undefined,
      defaultExposureRate: 100,
      selected: [],
      search: '',
      items: [],
      isListActive: true,
      dialog: false,
      resolve: null,
      reject: null,
      options: {
        color: 'white',
        width: 480,
      },
      tourSteps: [
        {
          target: '#panel-manager-panels',
          content: this.$t('deconve.onboarding.doohAnalytics.panelExposureRate.description'),
          params: {
            placement: 'top',
          },
        },
        {
          target: '#panel-manager-exposure-rate-for-all-panels',
          content: this.$t('deconve.onboarding.doohAnalytics.sameExposureRateForAllPanels.description'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      units: 'units/units',
    }),
    tableHeaders() {
      return [
        { text: this.$t('deconve.panel'), value: 'name' },
        { text: this.$t('deconve.exposureRate'), value: 'exposureRate' },
      ];
    },
    exposureRules() {
      return [
        (v) => !!v || this.$t('deconve.invalidValue'),
        (v) => (v && parseFloat(v) >= 0 && parseFloat(v) <= 100) || this.$t('deconve.invalidValue'),
      ];
    },
    exposureAllRules() {
      // value can be empty or positive between 0 and 100
      return [
        (v) => (!v || (parseFloat(v) >= 0 && parseFloat(v) <= 100)) || this.$t('deconve.invalidValue'),
      ];
    },
  },
  created() {
    this.initGetPanels();
  },
  methods: {
    initGetPanels() {
      this.items = this.units.map((panel) => (
        { id: panel.id, name: panel.name, exposureRate: this.defaultExposureRate }
      ));
    },
    applyAll() {
      this.items.forEach((_, index) => {
        this.items[index].exposureRate = this.exposureRateForAllPanels;
      });
      this.exposureRateForAllPanels = null;
    },
    startTour() {
      this.$refs.tour.start();
    },
    open(panels) {
      this.initGetPanels();

      // Copy the selected panels content and keep the reference to this.items
      const selectedPanels = [];

      panels.forEach((panel) => {
        const selectedPanel = this.items.find((item) => item.id === panel.id);

        if (selectedPanel) {
          selectedPanel.exposureRate = panel.exposureRate;

          selectedPanels.push(selectedPanel);
        }
      });

      // To update the screen only once, we set this.selected array here
      this.selected = selectedPanels;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      // Copy the selected panels content. We use this to avoid reference problem when user cancels
      // the update
      const selectedPanels = this.selected.map((panel) => ({ ...panel }));

      this.resolve(selectedPanels);
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      this.initGetPanels();
      this.reject();
    },
  },
};
</script>
